$primary-color:#d81f58;
$secondary-color:#3a3a3c;
$gray-color: #999999 !default;
$black-color: #3c4858 !default;


$link-color: #d81f58;
$checkboxes-text-color: red;

$blue-color1:#42C0FB;
$blue-color2:#0BB5FF;