@import "core/normalize";
@import "core/variables";
@import "core/mixins";
@import "core/base";
@import "~react-image-gallery/styles/scss/image-gallery.scss";

//Main Navigation
.wr-main-navigation{ display: flex;
    li{width:inherit;}
}
.wr-nav-wrapper,.wr-nav-right-wrapper{display:flex;}

//2019 Inventory Listing
a.wr-vehicle-link{color:black; display:block; overflow: hidden;
    &:hover{color:black;}
}
.wr-vehicle-item{ display: block; overflow: hidden; margin: 10px;
    .wr-vehicle-image{float:left; margin:0 10px 0 0; border:1px solid #e5e5e5; position: relative;
    img{width:180px; height: 120px; object-fit: contain;}
}
    .wr-vehicle-title{margin:0; font-size:16px; line-height: 1; font-weight:600; display:block; width:100%; margin-bottom:5px;}
    .wr-grid-vehicle-title{display:none;}
    .wr-vehicle-info{ float: left; width: calc(100% - 192px); width: -moz-calc(100% - 192px);
        
        .wr-attributes-col{font-size:12px; line-height: 1; display:block;}
        
        .wr-vehicle-by-col{
            .wr-listed-by,.wr-history-by{ font-size:11px;line-height:1; position: relative; padding: 0 5px 0 15px; margin-bottom: 5px;
                img{max-width: 100%; height: auto;}
                svg{position:absolute; top:-1px; left:0;}
            }
            .wr-history-by{margin-top:10px;}
        }
        .wr-price-info-col{
            .wr-price-figure{ text-align: right;
                del,ins{display: block; }
                del{text-decoration: none;text-decoration: line-through; color:grey;
                    .wr-price-amount{font-size:16px;line-height: 1; font-weight:700;
                        .wr-currency-symbol{font-size:10px;}
                    }
                }
                ins{text-decoration: none;}
                .wr-price-amount{font-size:18px;line-height: 1; font-weight:700;
                    .wr-currency-symbol{font-size:12px;}
                }

                .wr-tax-info{font-size: 12px; line-height: 12px;}
            }
        }
    }
}
.wr-listing{//margin:16px 0; overflow: hidden;
    .wr-grid-view{ //width:calc((100% - 48px)/4); width: -moz-calc((100% - 48px)/4); float: left; margin:0 16px 16px 0;
        //&:nth-child(4n){margin:0 0 16px 0;}
        .wr-vehicle-item{display: block;
            .wr-vehicle-image{float:none; margin:0 0 10px 0;
                img{width:192px; height: 145px; object-fit: contain;}
            }
            .wr-vehicle-info{ width:100%; float:none;
                .MuiGrid-container{
                    .MuiGrid-item{
                        max-width: 100%;
                        flex-basis: 100%;
                    }
                }
                .wr-vehicle-title{display:none;}
                .wr-attributes-tables{min-height:157px; min-height:208px;}
                .wr-attributes-col{width:100%; float: none;
                    
                    tbody{
                        width:100%; display:inline-block;
                        tr{
                        width:100%; display:inline-block;
                        &.wr-vin{
                            td{font-size:9px;}
                        }
                            th,td{width:50%; display:inline-block;}
                        }
                    }
                }
                .wr-vehicle-by-col{float:none; width:100%; margin:10px 0; display:block; overflow: hidden;
                    .wr-listed-by,.wr-history-by{margin:0; width:100%;margin-bottom: 5px;}
                    .wr-location br{display: none;}
                }
                .wr-price-info-col{float: none; width:100%;
                    .wr-price-figure{min-height: 46px;
                        del{float: left;}
                        ins{float: right;}
                        .wr-tax-info{display:block; width:100%; overflow: hidden;}
                    }
                }
            }
            
            .wr-grid-vehicle-title{display:block; height:35px; overflow:hidden;}
        } 
    }
}
tr.wr-attribute td span{display:block; height:13px; overflow: hidden;}


.wr-reset-filter-listing{margin-bottom: 1rem; padding:20px;}

.wr-features-list{
    .wr-options-list{
        padding:15px 0;
        border-bottom: 1px solid #e5e5e5;
        h4{margin-top:0; font-size:16px; font-weight:600;}

    }
}
@media only screen and (max-width: 959px) {
    h1.MuiTypography-h3{
        font-size:2rem;
    }
    h4.MuiTypography-h5{
        font-size:1rem,
    }
}
@media only screen and (max-width: 600px) {
    h1.MuiTypography-h3{
        font-size:1.375rem;
    }
    h2.MuiTypography-h4,h1.MuiTypography-h4{
        font-size:1.25rem;
        font-weight: bold;
    }
}

@media only screen and (max-width: 500px) {
.wr-vehicle-item{
    .wr-vehicle-image{
        img{
            width: 150px;
            height: 100px;
        }
    }
    .wr-vehicle-info{
        width: calc(100% - 162px);
        width: -moz-calc(100% - 162px);
        .wr-attributes-col{
            font-size:11px;
        }
    }
}
}
@media only screen and (max-width: 440px) {
    .wr-vehicle-item{
        .wr-vehicle-info{
            .MuiGrid-container{
                .MuiGrid-grid-xs-6{
                    max-width: 100%;
                    flex-basis: 100%;
                &:first-child{
                    display:none;
                }
                }
            }
        }
    }
}
